import React from "react"
import styled from "styled-components"
import Content from "../components/Content"
import AssociatesListing from "../components/ourTeam/Expansion/AssociatesListing"
import CarouselItemListing from '../components/ourTeam/CarouselItemListing'
import '../components/ourTeam/Expansion/expansion.css'
import Layout from "../components/Layout"
import Heading from '../components/elements/Heading'
import './our-team.css'
import SeniorAssociatesListing from "../components/ourTeam/Expansion/SeniorAssociatesListing"
import { graphql, useStaticQuery } from "gatsby"

export default function Team() {

  const data = useStaticQuery(graphql`
  query {
      contentfulBannerImages(path: {eq: "/OurTeam"}) {
        image {
          fluid(quality:100) {
            src
          }
        }
        heading
      }
      ourTeamBlock: contentfulPageTextAndHeadings(path: {eq: "/OurTeam"}) {
        mainHeading1
        mainHeading2
        mainHeading3
      }
    }
  `)

  const ImgDiv = styled.div`
  background-image: url(${data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

  return (
    <Layout>


      <ImgDiv>
        <h3
          style={{
            fontFamily: "Playfair Display",
            fontSize: 50,
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto",
            paddingTop: 100,
          }}
        >
          {data.contentfulBannerImages.heading}
          </h3>
      </ImgDiv>
      <div className="container mt-5 mb-5" style={{ textAlign: "-webkit-center"}}>

        <Heading className="headingTeam">
          <Content heading={data.ourTeamBlock.mainHeading1} />
        </Heading>
      </div>
        <CarouselItemListing />
        <div className="container center">
          <hr className="col divider" />
        </div>

      <div className="container mt-5 mb-5" style={{ textAlign: "-webkit-center"}}>
        <Heading className="headingTeam" ><Content heading={data.ourTeamBlock.mainHeading2} /></Heading>
      </div>
      <div className="my-5">
        <div className="mx-auto">
          <SeniorAssociatesListing/>
        </div>
      </div>

      <div className="container center">
        <hr className="col divider" />
      </div>

      <div className="container mt-5 mb-5" style={{ textAlign: "-webkit-center"}}>
        <Heading className="headingTeam" ><Content heading={data.ourTeamBlock.mainHeading3} /></Heading>
      </div>
      <div className="my-5">
        <div className="mx-auto">
          <AssociatesListing/>
        </div>
      </div>
    </Layout>
  )
}
