import React from "react"
import {  graphql, useStaticQuery } from 'gatsby'
import Associates from './Associates'


const AssociatesListing = ( ) => {

  const data = useStaticQuery(graphql`
    query {
        allContentfulAssociates(sort: { fields: createdAt }) {
          edges {
            node {
              id
              name
              email
              description{
                childContentfulRichText{
                  html
                }
              }
            }
          }
        }
      }
    `)
  const associatesCount = data.allContentfulAssociates.edges.length;
  let mid;
  if (associatesCount%2 === 0) {
    mid = associatesCount/2;
  } else {
    mid = Math.ceil(associatesCount/2);
  }
  return (
    <div className="row center text-justify">
      <div className="col-10 col-lg-4">
        {
          data.allContentfulAssociates.edges.slice(0,mid).map((edge) => {
            return (
              <div className="col" key={edge.node.id}>
                <Associates
                  name={edge.node.name}
                  description={edge.node.description.childContentfulRichText.html}
                  email = {edge.node.email}
                />
              </div>
            )
          })

        }
      </div>
      <div className="col-10 col-lg-4">
        {
          data.allContentfulAssociates.edges.slice(mid,associatesCount).map((edge) => {
            return (
              <div className="col" key={edge.node.id}>
                <Associates
                  name={edge.node.name}
                  description={edge.node.description.childContentfulRichText.html}
                  email = {edge.node.email}
                />
              </div>
            )
          })

        }
      </div>
    </div>
  )
}

export default AssociatesListing 
