import React from "react"
import PartnerListing from "./PartnersListing"


const CarouselItem = ({ name1, name2, name3, designation1, designation2, designation3, slug1, slug2, slug3, image1, image2, image3, carouselClass, buttonText }) => {
    return (
        <div className={carouselClass} data-interval="9000">

            <PartnerListing
                name1={name1}
                name2={name2}
                name3={name3}
                designation1={designation1}
                designation2={designation2}
                designation3={designation3}
                slug1={slug1}
                slug2={slug2}
                slug3={slug3}
                image1={image1}
                image2={image2}
                image3={image3}
                buttonText={buttonText}
            />

        </div>
    )
}

export default CarouselItem






























