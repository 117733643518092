import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import styled from "styled-components"
import './expansion.css'

const Button = styled.button`
  padding: 2.5% 0% 2.5% 0%;
  padding-bottom :7px;
  background-color: #94040b;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Rawline;
  border-radius: 50px;
  width: 130px;
  margin-top: 30px;
  font-weight: 600;
`

const useStyles = makeStyles({
    root: {
        width: "100%",
        borderRadius: 0,
    },
    heading: {
        color : "#94040b",
        fontWeight : 600
    },
})

const Associates = ({ name, description, email }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <ExpansionPanel
                style={{
                    background: "transparent",
                    border: "none",
                    borderBottomStyle: "solid",
                    borderColor: "#942411",
                    borderWidth: 1,
                    borderRadius: 0,
                }}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div
                        className={classes.heading}
                    >
                        <p className="center" >{name}</p>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="w-100">
                        <div className="row">
                            <div className="col-12" style={{ fontSize: 20 }} dangerouslySetInnerHTML={{ __html: description }}>

                            </div>
                            <div className="col-5 ml-auto">
                                <ExpansionPanelActions>
                                    {/* <div style={{justifyContent:'center'}}> */}
                                    <a href={"mailto:" + email}>
                                        <Button
                                            className="mr-0"
                                        >
                                            Email
                                        </Button>
                                    </a>
                                </ExpansionPanelActions>
                            </div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

export default Associates
