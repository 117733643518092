import React  from "react"
import './Expansion/expansion.css'
import FullProfile from "./FullProfile"
import './partner.css'

const Partner = ({ name, designation, image, slug, buttonText }) => {

  return (
    <div className="col-lg-4 col-md-8 mb-5">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-3 d-flex justify-content-center">
          <img
            src={image}
            className=""
            alt="..."
            height="180"
            style={{maxWidth:'none'}}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-3 d-inline">
          <h5
            style={{
              color: "#94040b",
              borderBottomStyle: "solid",
              borderColor: "#000000",
              fontFamily: "Rawline",
              fontWeight: 600,
              fontSize: 22,
              borderWidth: 1,

            }}
            dangerouslySetInnerHTML={{ __html: name }}
          >

          </h5>
          <div className="mb-2" dangerouslySetInnerHTML={{ __html: designation }}/>
        </div>
      </div>
      <FullProfile
        buttonText={buttonText}
        slug={slug}
      />
    </div>
  )
}

export default Partner
