import React from "react"
import Partner from './Partner'


const PartnerListing = ({ name1, name2, name3, designation1, designation2, designation3, slug1, slug2, slug3, image1, image2, image3, buttonText }) => {
    return (
        <div className="container">
            <div className="row">
                <Partner
                    name={name1}
                    designation={designation1}
                    image={image1}
                    slug={slug1}
                    buttonText={buttonText}
                />
                <Partner
                    name={name2}
                    designation={designation2}
                    slug2={slug2}
                    image={image2}
                    slug={slug2}
                    buttonText={buttonText}
                />
                <Partner
                    name={name3}
                    designation={designation3}
                    slug3={slug3}
                    image={image3}
                    slug={slug3}
                    buttonText={buttonText}
                />
            </div>
        </div>
    )
}

export default PartnerListing






























