import { Link } from "gatsby"
import React  from "react"
import styled from "styled-components"

const Button = styled.button`
color: #94040b;
border: none;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
font-family: Rawline;
border-radius: 50px;
border-style: solid;
border-color: #94040b;
border-width: 2px;
font-size: 95%;
height: 40px;
width: 46%;
background: transparent;
padding-bottom: 3px;
`

const FullProfile = ({ slug, buttonText}) => {

    return (
        <div className={""}>
            <Link to={'/profiles/'+slug}>
                {" "}
              <Button>{buttonText}</Button>{" "}
            </Link>
        </div>
    )
}

export default FullProfile






























