import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import CarouselItem from './CarouselItem'

export default function CarouselItemListing() {
    const data = useStaticQuery(graphql`
query {
    allContentfulProfileSlider(sort: {fields: contentfulid}) {
        edges {
          node {
            active
            contentfulid
            buttonText
            profile1 {
              id
              name
              designation
              slug
              image{
                  fluid (quality: 100) {
                      src
                  }
              }
            }
            profile2 {
              id
              name
              designation
              slug
              image{
                  fluid (quality: 100) {
                      src
                  }
              }
            }
            profile3 {
              id
              name
              designation
              slug
              image{
                  fluid (quality: 100) {
                      src
                  }
              }
            }
          }
        }
      }
  }
`)
    // console.log("profile1", data.allContentfulProfileSlider)


    return (
        
                <div className="row mt-5 mb-5">
                    <div className="col-md-12">
                        <div
                            id="carouselExampleInterval"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <ol className="carousel-indicators">
                                {
                                    data.allContentfulProfileSlider.edges.map((edge) => {
                                        const classname = edge.node.active ? "active" : ""
                                        // console.log("order id", edge.node.orderId)
                                        return (
                                          <li data-target="#carouselExampleInterval" data-slide-to={edge.node.contentfulid} key={edge.node.contentfulid} className={classname} />
                                        )
                                    })
                                }
                            </ol>
                            <div className="carousel-inner" style={{justifyContent:"center"}}>
                               <div className="align-middle"> {
                                    data.allContentfulProfileSlider.edges.map((edge) => {
                                        // console.log("profile1", edge.node.profile1.image[edge].fluid.src)

                                        const classname = edge.node.active ? 'active' : ''
                                        return (

                                            <CarouselItem
                                                key={edge.node.contentfulid}
                                                carouselClass={classname + ' carousel-item'}
                                                name1={edge.node.profile1.name}
                                                designation1={edge.node.profile1.designation}
                                                slug1={edge.node.profile1.slug}
                                                image1={edge.node.profile1.image[0].fluid.src}
                                                name2={edge.node.profile2.name}
                                                designation2={edge.node.profile2.designation}
                                                slug2={edge.node.profile2.slug}
                                                image2={edge.node.profile2.image[0].fluid.src}
                                                name3={edge.node.profile3.name}
                                                designation3={edge.node.profile3.designation}
                                                slug3={edge.node.profile3.slug}
                                                image3={edge.node.profile3.image[0].fluid.src}
                                                buttonText={edge.node.buttonText}
                                            />
                                        )
                                    })

                                }
                                </div>
                            </div>
                            <a
                                className="carousel-control-prev"
                                href={"#carouselExampleInterval"}
                                role="button"
                                data-slide="prev"
                            >
                                <span
                                  className="carousel-control-prev-icon"
                                  aria-hidden="true"
                                  />
                                <span className="sr-only">Previous</span>
                            </a>
                            <a
                                className="carousel-control-next"
                                href={"#carouselExampleInterval"}
                                role="button"
                                data-slide="next"
                            >
                                <span
                                  className="carousel-control-next-icon"
                                  aria-hidden="true"
                                  />
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
           
    )
}
